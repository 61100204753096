import {
  AptosWalletAdapterProvider,
  Wallet,
  WalletName,
  WalletReadyState,
  isRedirectable,
  useWallet,
} from "@aptos-labs/wallet-adapter-react";
import { AddressUpsert } from "@fscrypto/domain/wallet";
import { Button } from "@fscrypto/ui";
import { useEffect } from "react";

import { BitgetWallet } from "@bitget-wallet/aptos-wallet-adapter";
import { MartianWallet } from "@martianwallet/aptos-wallet-adapter";
import { MSafeWalletAdapter } from "@msafe/aptos-wallet-adapter";
import { NightlyWallet } from "@nightlylabs/aptos-wallet-adapter-plugin";
import { OKXWallet } from "@okwallet/aptos-wallet-adapter";
import { OnekeyWallet } from "@onekeyfe/aptos-wallet-adapter";
import { RiseWallet } from "@rise-wallet/wallet-adapter";
import { TokenPocketWallet } from "@tp-lab/aptos-wallet-adapter";
import { TrustWallet } from "@trustwallet/aptos-wallet-adapter";
import { WelldoneWallet } from "@welldone-studio/aptos-wallet-adapter";
import { FewchaWallet } from "fewcha-plugin-wallet-adapter";
import { PetraWallet } from "petra-plugin-wallet-adapter";
import { NONCE, SIGN_MESSAGE } from "~/utils/wallet-connectors/aptos";

const wallets = [
  new PetraWallet(),
  new BitgetWallet(),
  new FewchaWallet(),
  new MartianWallet(),
  new MSafeWalletAdapter(),
  new NightlyWallet(),
  new RiseWallet(),
  new TokenPocketWallet(),
  new TrustWallet(),
  new WelldoneWallet(),
  new OKXWallet(),
  new OnekeyWallet(),
];

export const AptosWalletConnectors = ({ setAddress }: { setAddress: (address: AddressUpsert) => void }) => {
  return (
    <AptosWalletAdapterProvider
      plugins={wallets}
      autoConnect={false}
      onError={(e) => {
        console.error(e);
      }}
    >
      <>
        <Wallets setAddress={setAddress} />
      </>
    </AptosWalletAdapterProvider>
  );
};

const Wallets = ({ setAddress }: { setAddress: (address: AddressUpsert) => void }) => {
  const { connected, account, signMessage, wallets } = useWallet();

  useEffect(() => {
    if (connected && account) {
      const payload = {
        address: true,
        message: SIGN_MESSAGE,
        nonce: NONCE,
      };

      signMessage(payload).then((res) => {
        if (!res.address || typeof res.signature !== "string") {
          console.error("Invalid response from signMessage", res);
          return;
        }

        if (typeof account.publicKey !== "string") {
          // we don't support multisig
          console.error("Invalid publicKey", account.publicKey);
          return;
        }
        setAddress({
          address: res.address,
          chain: "aptos",
          isVerified: true,
          isDefault: true,
          connectorMeta: {
            type: "Aptos",
            publicKey: account.publicKey,
            signature: res.signature,
          },
        });
      });
    }
  }, [connected, account]);

  return (
    <div className="scrollbar mt-4 max-h-[600px] space-y-2 overflow-y-scroll">
      {wallets?.map((wallet) => {
        return WalletView(wallet as Wallet);
      })}
    </div>
  );
};

const WalletView = (wallet: Wallet) => {
  const { connect } = useWallet();
  const isWalletReady =
    wallet.readyState === WalletReadyState.Installed || wallet.readyState === WalletReadyState.Loadable;
  const mobileSupport = wallet.deeplinkProvider;

  const onWalletConnectRequest = async (walletName: WalletName) => {
    try {
      await connect(walletName);
    } catch (e) {
      console.error(e);
    }
  };

  /**
   * If we are on a mobile browser, adapter checks whether a wallet has a `deeplinkProvider` property
   * a. If it does, on connect it should redirect the user to the app by using the wallet's deeplink url
   * b. If it does not, up to the dapp to choose on the UI, but can simply disable the button
   * c. If we are already in a in-app browser, we dont want to redirect anywhere, so connect should work as expected in the mobile app.
   *
   * !isWalletReady - ignore installed/sdk wallets that dont rely on window injection
   * isRedirectable() - are we on mobile AND not in an in-app browser
   * mobileSupport - does wallet have deeplinkProvider property? i.e does it support a mobile app
   */
  if (!isWalletReady && isRedirectable()) {
    // wallet has mobile app
    if (mobileSupport) {
      return <WalletCard wallet={wallet} disabled={false} onClick={() => onWalletConnectRequest(wallet.name)} />;
    }
    // wallet does not have mobile app
    return <WalletCard wallet={wallet} disabled={true} desktopOnly={true} />;
  } else {
    // we are on desktop view
    return <WalletCard wallet={wallet} disabled={!isWalletReady} onClick={() => onWalletConnectRequest(wallet.name)} />;
  }
};

const WalletCard = ({
  wallet,
  disabled,
  onClick,
  desktopOnly,
}: {
  wallet: Wallet;
  disabled: boolean;
  desktopOnly?: boolean;
  onClick?: () => void;
}) => {
  return (
    <div
      className="mx-auto flex w-full items-center justify-between rounded-lg border border-gray-200 p-4"
      key={wallet.name}
      onClick={onClick}
    >
      <img src={wallet.icon} alt={wallet.name} className="m-1 h-5 w-5 flex-shrink-0" />
      <p>{wallet.name}</p>
      {desktopOnly ? (
        <p className="text-muted-foreground text-sm">Desktop Only</p>
      ) : disabled ? (
        <Button variant="secondary" size="md" className="px-6" asChild>
          <a href={wallet.url} target="_blank" rel="noreferrer">
            Install
          </a>
        </Button>
      ) : (
        <Button variant="primary" size="md" onClick={onClick}>
          Connect
        </Button>
      )}
    </div>
  );
};
