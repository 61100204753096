import type { wallet } from "@fscrypto/domain";
import { AddressUpsert } from "@fscrypto/domain/wallet";
import { Button, Input, Modal, Tooltip } from "@fscrypto/ui";
import { AlertTriangle, CheckSquare2Icon, Loader2Icon } from "lucide-react";
import { useEffect, useMemo, useState } from "react";
import { truncateAddress } from "~/utils/helpers";
import { AptosWalletConnectors } from "./connectors/aptos";
import { Dynamic } from "./connectors/dynamic";
import { NearWalletConnectors } from "./connectors/near";
import { SeiWalletConnectors } from "./connectors/sei";
import { NetworksDropdown } from "./networks-dropdown";
import { useAddWalletModal } from "./state/use-add-wallet-modal";
import { useWallets } from "./state/use-wallet";

const DYNAMIC_NETWORKS = ["ethereum", "avalanche", "polygon", "flow", "solana", "axelar", "blast", "kaia", "gnosis"];

export const AddWalletModal = () => {
  const addWalletModalState = useAddWalletModal();
  const walletState = useWallets();
  const [error, setError] = useState<Error | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const newAddress = addWalletModalState?.newAddress;
  const selectedNetwork = addWalletModalState?.selectedNetwork;

  const createNewAddress = async (address: wallet.AddressUpsert) => {
    try {
      setIsLoading(true);
      await walletState.upsert(address);
      addWalletModalState?.setModalClose();
    } catch (e) {
      setError(e as Error);
    } finally {
      setIsLoading(false);
    }
  };

  // save automatically and spare an extra click
  useEffect(() => {
    if (
      newAddress?.connectorMeta?.type &&
      ["Dynamic", "Aptos", "Near", "Sei"].includes(newAddress.connectorMeta.type)
    ) {
      createNewAddress(newAddress);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newAddress]);

  const showVerifiedAddressText = newAddress?.isVerified && isLoading;

  // Loading state (loading the networks)
  if (addWalletModalState?.isLoadingNetworks || !addWalletModalState?.networks) return null;

  const networks = addWalletModalState.networks;

  return (
    <Modal
      open={addWalletModalState.isModalOpen ?? false}
      setOpen={(open) => {
        // hack. Dynamic component interferes with the modal close.
        // If the dynamic component might be open, we don't close the modal (only if user clicks the cancel button)
        if (!selectedNetwork && !open) {
          addWalletModalState?.setModalClose();
        }
      }}
    >
      {/* To avoid a race condition with loading styles when dynamically loading the Dynamic.xyz component based on dropdown selection */}
      <link rel="stylesheet" href="https://app.dynamic.xyz/assets/templates/minimal.css" />
      <div className="px-6 py-8">
        <h2 className="text-lg">{`Add ${`${selectedNetwork?.displayName ?? ""} `}Wallet`}</h2>
        <div className="py-4">
          {!addWalletModalState?.hideSelectNetwork && (
            <div className="flex justify-center">
              <NetworksDropdown
                networks={networks}
                selected={selectedNetwork}
                onSelect={(n) => addWalletModalState?.setSelectedNetwork(n)}
              />
            </div>
          )}
          {selectedNetwork === undefined ? null : showVerifiedAddressText ? (
            <VerifiedAddress address={newAddress.address} />
          ) : (
            <AddWallet
              network={selectedNetwork}
              setAddress={(a) => addWalletModalState?.setNewAddress(a)}
              hideUnverifiedAddressInput={addWalletModalState.hideUnverifiedAddressInput}
            />
          )}
        </div>
        <div>{error && <p className="text-red-500">{error?.message}</p>}</div>
        <div className="flex justify-end gap-2">
          <Button variant="secondary" size="md" onClick={() => addWalletModalState?.setModalClose()}>
            Cancel
          </Button>
          <Button
            variant="primary"
            size="md"
            disabled={newAddress === undefined}
            onClick={() => createNewAddress(newAddress!)}
          >
            {isLoading ? <Loader2Icon className="text-contrast size-4 animate-spin" /> : <p>Save</p>}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

const AddWallet = ({
  network,
  setAddress,
  hideUnverifiedAddressInput,
}: {
  network: wallet.Network;
  setAddress: (address: AddressUpsert) => void;
  hideUnverifiedAddressInput?: boolean;
}) => {
  const networkCanUseDynamic = useMemo(() => {
    return network && DYNAMIC_NETWORKS.includes(network.chain);
  }, [network]);

  const networkIsAptos = network.chain === "aptos";
  const networkIsNear = network.chain === "near";
  const networkIsSei = network.chain === "sei";

  const networkHasConnectors = networkCanUseDynamic || networkIsAptos || networkIsSei || networkIsNear;

  return (
    <>
      {networkCanUseDynamic && <Dynamic network={network} setAddress={setAddress} />}
      {networkIsAptos && <AptosWalletConnectors setAddress={setAddress} />}
      {networkIsNear && <NearWalletConnectors setAddress={setAddress} />}
      {networkIsSei && <SeiWalletConnectors setAddress={setAddress} />}
      {!hideUnverifiedAddressInput && (
        <div className="mb-4 mt-8">
          <AddWalletAddress network={network} setAddress={setAddress} advisory={networkHasConnectors} />
        </div>
      )}
    </>
  );
};

const VerifiedAddress = ({ address }: { address: string }) => {
  return (
    <div className="flex items-center justify-center gap-2 py-5">
      <CheckSquare2Icon className="text-green-70 size-4" />
      <p>{truncateAddress(address)}</p>
    </div>
  );
};

const AddWalletAddress = ({
  network,
  setAddress,
  advisory = false,
}: {
  network: wallet.Network;
  setAddress: (address: AddressUpsert) => void;
  advisory?: boolean;
}) => {
  return (
    <div className="space-y-1">
      <div className="text-muted-foreground pl-1  ">
        {advisory ? (
          <p className="flex items-center">
            <span className="pr-1">or enter a wallet address</span>
            <Tooltip content="This address will be unverified and can only be used to claim rewards" side="top">
              <AlertTriangle className="size-4 text-orange-70 inline" />
            </Tooltip>
          </p>
        ) : (
          "Enter wallet address"
        )}
      </div>
      <Input
        name="addAddress"
        onChange={(e) => {
          setAddress({
            address: e.target.value,
            chain: network.chain,
            isDefault: false,
            isVerified: false,
          });
        }}
      />
    </div>
  );
};
