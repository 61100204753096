import { CurrentUser } from "@fscrypto/domain";
import { Team } from "@fscrypto/domain/team";
import { TeamInvitation } from "@fscrypto/domain/team-invitation";
import type { LoaderFunctionArgs } from "@remix-run/node";
import { Outlet, useRouteError } from "@remix-run/react";
import { useEffect } from "react";
import { Shell } from "~/features/app-shell";
import { useRewards } from "~/features/earn/state/use-rewards";
import ErrorPage from "~/features/errors/ErrorPage";
import { createProfileManagerActor } from "~/features/profile-manager/state";
import { createTeamsActor } from "~/features/teams/actors/teams";
import { useAddWalletModal } from "~/features/wallet-management/state/use-add-wallet-modal";
import { useWallets } from "~/features/wallet-management/state/use-wallet";
import { useLoaderData } from "~/remix";
import { services } from "~/services/services.server";

export const loader = async ({ request, context }: LoaderFunctionArgs) => {
  // authenticate
  const user = await services.auth.getCurrentUser(request);

  if (!user) return { teams: [], invites: [] };

  const teams = await services.teams.findAllByUser(user.id);
  const invites = await services.teamInvitations.findByUserId(user.id);

  return {
    user,
    teams,
    invites,
    team: teams.find((t) => t.profileId === user.currentProfileId),
  };
};

export const ErrorBoundary = () => {
  const error = useRouteError() as Error;
  return (
    <Shell teams={[]}>
      <ErrorPage message={error.message} />
    </Shell>
  );
};

export default function AppShell() {
  const { teams, invites, team, user } = useLoaderData<{
    team: Team;
    teams: Team[];
    invites: TeamInvitation[];
    user?: CurrentUser;
  }>();
  const { initialize: initializeWallet } = useWallets();
  const { initialize: initializeAddWalletModal } = useAddWalletModal();
  const { fetch: initializeRewards } = useRewards();
  useEffect(() => {
    if (team) {
      createTeamsActor(`teams-${team.id}`, { team, teamId: team.id });
    }
    createProfileManagerActor("profile-manager", { teams, invites });

    if (user) {
      initializeWallet();
      initializeAddWalletModal();
      initializeRewards(user.currentProfileId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Shell teams={teams}>
      <Outlet />
    </Shell>
  );
}
